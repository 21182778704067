import TTLCache from "@isaacs/ttlcache";
import { cache } from "./cache";
import { persistentDevCache } from "./dev";
import type { AuthorPerm, ParsedAccounts } from "./hive";
import { fetchContent } from "./hive";
import type { Authorization } from "./infra";
import { API, postData } from "./infra";
import { isSSR } from "./ssr";
import type { ThreadContent } from "./thread";
import { ShortContent } from "./types/shorts.type";

export interface CachedDiscussion {
  fake?: boolean;
  author: string;
  permlink: string;
  body: string;
  created: string;
  replies: any[];
  children: number;
  depth: number;
  stats: Stats;
  author_reputation: number;
  tickers: any[];
  mentions: string[];
  hashtags: string[];
  links: string[];
  images: any[];
  threadstorm: boolean;
  deleted: boolean;
}

export interface CachedList {
  account: string;
  description: string;
  followers: number;
  hashtags: string[];
  image: string;
  name: string;
  private: boolean;
  slug: string;
  users: string[];
  words: string[];
  is_following: boolean;
}

export interface Bookmark {
  account: string;
  folder: string;
  permlinks: string[];
}

export interface Stats {
  total_votes: number;
}

export enum CacheFeeds {
  LatestFeed,
  TrendingFeed,
  TrendingTags
}

const cacheFeedEquals = ["latest", "trending"];

enum AccountFeeds {
  Latest,
  Oldest,
  Trending
}

export interface ThreadStats {
  total_count: number;
  total_votes_sum: number;
  total_replies_count: number;
}

export interface AccountDelegation {
  [key: string]: { premium: boolean; state: "staked" | "liquid" };
}

export const SERVER_ADRESS = isSSR()
  ? "http://86.107.168.23"
  : "https://cache2.inleo.io";

export const BACKUP_ADDRESS = "https://backupcache.inleo.io";

/*
export const SERVER_ADRESS = isSSR()
  ? "http://0.0.0.0:8080"
  : "http://localhost:8080";
isSSR() ? "http://92.205.162.218:8080" : "https://inleo.io/leocache";
("http://134.209.34.152/leocache");
*/
let threadsDisposalHolder = new Set([]);

const feedsFetching = async (self: any, key: CacheFeeds, address: string) => {
  let result = await (
    await fetch(`${SERVER_ADRESS}/${cacheFeedEquals[key]}`, {
      headers: {
        "Keep-Alive": "timeout=3600000, max=100000",
        Connection: "keep-alive"
      }
    })
  ).json();

  result.forEach((thread: CachedDiscussion) => {
    const authorPerm = `${thread.author}/${thread.permlink}`;
    self.threads.set(authorPerm, [thread]);
  });

  void (async function () {
    cache.getThreads(result.slice(0, 5));
  })();

  const accounts = await cache.getAccounts([
    ...new Set([...result.map((thread: CachedDiscussion) => thread.author)])
  ]);
  const enriched_result = result.map((thread: CachedDiscussion) => ({
    ...thread,
    display_name: accounts[thread.author]?.posting_json_metadata?.profile?.name
  }));

  return enriched_result;
};

const singularFeedFetching = async (
  self: any,
  key: CacheFeeds,
  address: string,
  endpoint: string
) => {
  let result;
  try {
    result = await (
      await fetch(`${address}/${endpoint}`, {
        headers: {
          "Keep-Alive": "timeout=3600000, max=100000",
          Connection: "keep-alive",
          "Accept-Encoding": "gzip, deflate, br"
        },
        keepalive: true
      })
    ).json();
  } catch {
    return [];
  }

  result.forEach((thread: CachedDiscussion) => {
    const authorPerm = `${thread.author}/${thread.permlink}`;
    self.threads.set(authorPerm, [thread]);
  });

  let accounts: ParsedAccounts | never[];

  try {
    accounts = await cache.getAccounts([
      ...new Set([...result.map((thread: CachedDiscussion) => thread.author)])
    ]);
  } catch {
    accounts = [];
  }
  const enriched_result = result.map((thread: CachedDiscussion) => ({
    ...thread,
    display_name:
      accounts[thread?.author]?.posting_json_metadata?.profile?.name ||
      thread.author
  }));

  return enriched_result;
};

class LeoCache {
  private feeds: TTLCache<CacheFeeds, CachedDiscussion[]> = new TTLCache({
    ttl: 1 * 15 * 1_000,
    dispose: async (value, key, reason) => {
      if (!isSSR()) return;
      if ((key === 0 || key === 1) && reason === "stale") {
        setTimeout(async () => {
          try {
            const enriched_result = await feedsFetching(
              this,
              key,
              SERVER_ADRESS
            );
            this.feeds.set(key, enriched_result);
          } catch (e) {
            const enriched_result = await feedsFetching(
              this,
              key,
              BACKUP_ADDRESS
            );
            this.feeds.set(key, enriched_result);
          }
        }, 0);
      }
    }
  });
  private tags: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    max: 500,
    ttl: 10 * 60 * 1_000
  });
  private threads: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    max: 5000,
    ttl: 1 * 60 * 1_000,
    dispose: (value, key, reason) => {
      if (!isSSR()) return;
      if (reason !== "stale") return;
      if (threadsDisposalHolder.size > 50) {
        //console.log("Thread disposal holder emptied with size", threadsDisposalHolder.size)
        threadsDisposalHolder.forEach((key: string) => {
          void (async function () {
            try {
              const [author, permlink] = key.split("/");
              const result = await (
                await fetch(`${SERVER_ADRESS}/single/${author}/${permlink}`, {
                  headers: {
                    "Keep-Alive": "timeout=3600000, max=100000",
                    Connection: "keep-alive"
                  }
                })
              ).json();

              leocache.threads.set(key, result);
            } catch (e) {
              console.log("Erro adding ", key, "to disposal service.");
              console.log(e);
            }
          })();
        });

        threadsDisposalHolder = new Set([]);
      } else {
        //console.log("Added thread ", key ," to holder", threadsDisposalHolder.size)
        if (!threadsDisposalHolder.has(key as never))
          threadsDisposalHolder.add(key as never);
      }
    }
  });
  private author: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    max: 500,
    ttl: 5 * 60 * 1_000
  });
  private stats: TTLCache<string, ThreadStats[]> = new TTLCache({
    ttl: 30 * 60 * 1_000
  });
  private following: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    ttl: 1 * 25 * 1_000
  });
  private foryou: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    ttl: 1 * 2 * 1_000
  });
  private authorLists: TTLCache<string, CachedList[]> = new TTLCache({
    ttl: 1 * 60 * 1_000
  });
  private list: TTLCache<string, CachedList> = new TTLCache({
    ttl: 1 * 5 * 1_000
  });
  private lists: TTLCache<string, CachedList[]> = new TTLCache({
    ttl: 1 * 60 * 1_000
  });
  private listFeed: TTLCache<string, ThreadContent[]> = new TTLCache({
    ttl: 1 * 60 * 1_000
  });
  private bookmarks: TTLCache<string, Bookmark[]> = new TTLCache({
    ttl: 30 * 60 * 60 * 1_000
  });
  private infrasettings: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    ttl: 30 * 60 * 60 * 1_000
  });
  private threadcasts: TTLCache<string, CachedDiscussion[]> = new TTLCache({
    ttl: 5 * 60 * 1_000
  });
  private premiumlist: TTLCache<string, String[]> = new TTLCache({
    ttl: 10 * 60 * 1_000
  });
  private delegationlist: TTLCache<string, String[]> = new TTLCache({
    ttl: 1 * 30 * 1_000
  });
  private referral: TTLCache<string, String[]> = new TTLCache({
    ttl: 1 * 30 * 1_000
  });

  get latestFeed() {
    if (this.feeds.get(CacheFeeds.LatestFeed)) {
      return this.feeds.get(CacheFeeds.LatestFeed);
    }
    return (async () => {
      try {
        const enriched_result = await singularFeedFetching(
          this,
          CacheFeeds.LatestFeed,
          SERVER_ADRESS,
          "latest"
        );
        this.feeds.set(CacheFeeds.LatestFeed, enriched_result);
        return enriched_result;
      } catch (e) {
        console.log("Primary server fetch failed, trying backup address", e);
        try {
          const enriched_result = await singularFeedFetching(
            this,
            CacheFeeds.LatestFeed,
            BACKUP_ADDRESS,
            "latest"
          );
          this.feeds.set(CacheFeeds.LatestFeed, enriched_result);
          return enriched_result;
        } catch (backupError) {
          console.log("Backup server fetch failed", backupError);
          return [];
        }
      }
    })();
  }

  get trendingFeed() {
    if (this.feeds.get(CacheFeeds.TrendingFeed)) {
      return this.feeds.get(CacheFeeds.TrendingFeed);
    }
    return (async () => {
      try {
        const enriched_result = await singularFeedFetching(
          this,
          CacheFeeds.TrendingFeed,
          SERVER_ADRESS,
          "trending"
        );
        this.feeds.set(CacheFeeds.TrendingFeed, enriched_result);
        return enriched_result;
      } catch (e) {
        console.log("Primary server fetch failed, trying backup address", e);
        try {
          const enriched_result = await singularFeedFetching(
            this,
            CacheFeeds.TrendingFeed,
            BACKUP_ADDRESS,
            "trending"
          );
          this.feeds.set(CacheFeeds.TrendingFeed, enriched_result);
          return enriched_result;
        } catch (backupError) {
          console.log("Backup server fetch failed", backupError);
          return [];
        }
      }
    })();
  }

  get trendingTags() {
    if (this.feeds.get(CacheFeeds.TrendingTags)) {
      return this.feeds.get(CacheFeeds.TrendingTags);
    }
    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/tags_trending`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        this.feeds.set(CacheFeeds.TrendingTags, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  constructor() {}

  public tagFeed(tag: string, sort: string) {
    if (this.tags.get(`${tag}/${sort}`)) {
      return this.tags.get(`${tag}/${sort}`);
    }
    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/tags/${tag}/${sort}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        result.forEach((thread: CachedDiscussion) => {
          const authorPerm = `${thread.author}/${thread.permlink}`;
          this.threads.set(authorPerm, [thread]);
        });
        this.tags.set(`${tag}/${sort}`, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public singleThread(thread: AuthorPerm) {
    const authorPerm = `${thread.author}/${thread.permlink}`;
    if (this.threads.get(authorPerm)) {
      return this.threads.get(authorPerm);
    }
    return (async () => {
      try {
        const result = await (
          await fetch(
            `${SERVER_ADRESS}/single/${thread.author}/${thread.permlink}`,
            {
              headers: {
                "Keep-Alive": "timeout=3600000, max=100000",
                Connection: "keep-alive"
              }
            }
          )
        ).json();
        if (result.length === 0) {
          const fallback = [
            await fetchContent({
              author: thread?.author,
              permlink: thread?.permlink
            } as AuthorPerm)
          ];

          this.threads.set(
            authorPerm,
            fallback as unknown as CachedDiscussion[]
          );
          return fallback;
        } else {
          this.threads.set(authorPerm, result);
          return result;
        }
      } catch (e) {
        return [];
      }
    })();
  }

  public authorStats(author: string) {
    if (this.stats.get(author)) {
      return this.stats.get(author);
    }

    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/stats/account/${author}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();

        this.stats.set(author, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public authorFeed(
    author: string,
    sorting_type: AccountFeeds,
    depth?: boolean
  ) {
    if (this.author.get(`${author}+${depth}+${sorting_type}`)) {
      return this.author.get(`${author}+${depth}+${sorting_type}`);
    }

    const feedNameStringified = AccountFeeds[sorting_type].toLowerCase();

    return (async () => {
      try {
        const result = await (
          await fetch(
            `${SERVER_ADRESS}/author/${author}/${depth}/${feedNameStringified}`,
            {
              cache:
                sorting_type === AccountFeeds.Oldest ? "force-cache" : "default"
            }
          )
        ).json();
        this.author.set(`${author}+${depth}+${sorting_type}`, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public authorSearch(author: string, query: string) {
    if (!query) return [];

    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/author/search/${author}/${query}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public tagSuggestion(_tags: string[]) {
    const tags = _tags?.filter
      ? _tags?.filter(tag => !tag?.startsWith("hive-"))
      : [];
    return (async () => {
      try {
        const result = await (
          await fetch(
            `${SERVER_ADRESS}/suggestion/${
              tags[Math.floor(Math.random() * tags.length)]
            }/${tags[Math.floor(Math.random() * tags.length)]}`,
            {
              headers: {
                "Keep-Alive": "timeout=3600000, max=100000",
                Connection: "keep-alive"
              }
            }
          )
        ).json();
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public tagSearch(tag: string, query: string) {
    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/tag/search/${tag}/${query}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        return result;
      } catch (e) {
        console.log("error", e);

        return [];
      }
    })();
  }

  public genericSearch(query: string) {
    if (!query) return [];

    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/search/${query}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public premiumUsersList() {
    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/stats/premium`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public getDelegationList(): Promise<AccountDelegation> {
    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/delegations`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        return result as AccountDelegation;
      } catch (e) {
        return {};
      }
    })();
  }

  public authorFollowing(author: string) {
    if (this.following.get(author)) {
      return this.following.get(author);
    }

    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/following/${author}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        result.forEach((thread: CachedDiscussion) => {
          const authorPerm = `${thread.author}/${thread.permlink}`;
          this.threads.set(authorPerm, [thread]);
        });
        this.following.set(author, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public authorForYou(author: string) {
    if (this.foryou.get(author)) {
      return this.foryou.get(author);
    }

    return (async () => {
      try {
        const result = await (
          await fetch(`${SERVER_ADRESS}/foryou/${author}`, {
            headers: {
              "Keep-Alive": "timeout=3600000, max=100000",
              Connection: "keep-alive"
            }
          })
        ).json();
        result.forEach((thread: CachedDiscussion) => {
          const authorPerm = `${thread.author}/${thread.permlink}`;
          this.threads.set(authorPerm, [thread]);
        });
        this.foryou.set(author, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public getAuthorLists(
    author: string,
    public_key: string,
    signature: string,
    hivesigner?: boolean
  ) {
    if (this.authorLists.get(author)) {
      return this.authorLists.get(author);
    }

    return (async () => {
      try {
        const result = await postData(`${SERVER_ADRESS}/lists/personal`, {
          account: author,
          public_key,
          signature,
          hivesigner
        });

        this.authorLists.set(author, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public getSingleList(
    author: string,
    slug: string,
    account: string,
    signature: string,
    public_key: string,
    hivesigner?: boolean
  ) {
    if (this.list.get(slug)) {
      return this.list.get(slug);
    }

    return (async () => {
      try {
        const result = await postData(
          `${SERVER_ADRESS}/lists/${author}/${slug}`,
          {
            account,
            signature,
            public_key,
            hivesigner
          }
        );

        this.list.set(slug, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public getInfraSettings(accountName: string, renew?: boolean) {
    if (renew !== true && this.infrasettings.get(accountName)) {
      return this.infrasettings.get(accountName);
    }

    return (async () => {
      try {
        const result = fetch(`${API}/settings/${accountName}`, {
          headers: {
            Connection: "keep-alive",
            // "Cache-Control": "max-age=30, must-revalidate",
            "Cache-Control": "no-cache"
          }
        }).then(x => x.json());

        this.infrasettings.set(accountName, await result);
        return await result;
      } catch {
        return {
          account: accountName,
          settings: {
            theme: "Light",
            color: "Orange",
            typography: 16,
            thread_weight: 0,
            post_weight: 0,
            short_weight: 0,
            post_reward: "Half",
            post_auto_reblog: false,
            post_auto_twitter: false,
            hide_links: false,
            default_currency: "USD",
            default_feed: "latest",
            default_beneficiaries: ["leofinance"]
          }
        };
      }
    })();
  }

  public getAllLists(
    account: string,
    public_key: string,
    signature: string,
    hivesigner?: boolean
  ) {
    if (this.lists.get("all")) {
      return this.lists.get("all");
    }

    return (async () => {
      try {
        const result = await postData(`${SERVER_ADRESS}/lists`, {
          account,
          public_key,
          signature,
          hivesigner
        });

        this.lists.set("all", result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public async getListFeed(
    author: string,
    slug: string,
    account: string,
    signature: string,
    public_key: string,
    hivesigner?: boolean
  ) {
    if (this.listFeed.get(slug)) {
      return this.listFeed.get(slug);
    }

    return (async () => {
      try {
        const result = await postData(
          `${SERVER_ADRESS}/lists/feed/${author}/${slug}`,
          {
            account,
            signature,
            public_key,
            hivesigner
          }
        );

        this.listFeed.set(slug, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  public getAccountBookmarks(account: string, renew?: boolean) {
    if (renew !== true && this.bookmarks.get(account)) {
      return this.bookmarks.get(account);
    }

    return (async () => {
      try {
        const result = await fetch(`${SERVER_ADRESS}/bookmarks/${account}`, {
          headers: {
            "Keep-Alive": "timeout=3600000, max=100000",
            Connection: "keep-alive"
          }
        }).then(async x => await x.json());

        this.bookmarks.set(account, result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }

  // latest threadcasts limited by length of 5
  public getLatestThreadcasts() {
    if (this.threadcasts.get("latest")) {
      return this.threadcasts.get("latest") || [];
    }

    return (async () => {
      try {
        const result = await fetch(`${SERVER_ADRESS}/threadcasts`, {
          headers: {
            "Keep-Alive": "timeout=3600000, max=100000",
            Connection: "keep-alive"
          }
        }).then(async x => await x.json());

        this.threadcasts.set("latest", result);
        return result;
      } catch (e) {
        return [];
      }
    })();
  }
}

interface SaveSeen {
  authorization: Authorization;
  permlinks: string[];
}

export async function saveLeoCacheSeen(saveSeen: SaveSeen) {
  if (!saveSeen.authorization.signature) return;

  try {
    return postData(`${SERVER_ADRESS}/seen`, saveSeen);
  } catch (err) {
    console.log(err);
  }
}

export async function checkPremium(account: string) {
  if (!account) return;

  try {
    return await fetch(`${SERVER_ADRESS}/premium/check/${account}`, {
      headers: {
        "Cache-Control": "no-cache",
        connection: "keep-alive",
        "Keep-Alive": "timeout=3600000, max=100000"
      }
    }).then(async x => await x.json());
  } catch (err) {
    return await fetch(`${BACKUP_ADDRESS}/premium/check/${account}`, {
      headers: {
        "Cache-Control": "no-cache",
        connection: "keep-alive",
        "Keep-Alive": "timeout=3600000, max=100000"
      }
    }).then(async x => await x.json());
  }
}

interface List {
  name: string;
  description?: string;
  image?: string | null;
  private?: boolean;
}

export async function createList(
  list: List,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return fetch(`${SERVER_ADRESS}/lists/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      account,
      signature,
      public_key,
      hivesigner,
      ...list
    })
  });
}

// /lists/modify/{field}/{type}/{account_name}/{list_name}/{update}
// field alan; private, name, description, hashtag, users olabilir
// type: field hashtag ya da users olduğu zaman type add ya da remove olabilir. name, image, description, private olduğu zaman update olabilir
// update: eklenecek/çıkarılacak input

interface RemoveListPayload {
  list_slug: string;
}

export async function removeList(
  account: string,
  signature: string,
  public_key: string,
  hivesigner: boolean,
  { list_slug }: RemoveListPayload
) {
  return await fetch(`${SERVER_ADRESS}/lists/remove/${list_slug}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Connection: "keep-alive"
    },
    body: JSON.stringify({
      account,
      signature,
      public_key,
      hivesigner
    })
  });
}

interface UpdateListPayload {
  field: "private" | "name" | "description" | "hashtag" | "users";
  type: "add" | "remove" | "update";
  author: string;
  list: string;
  update: string;
}

export async function editList(
  account: string,
  signature: string,
  public_key: string,
  hivesigner: boolean,
  { field, type, author, list, update }: UpdateListPayload
) {
  return await fetch(
    `${SERVER_ADRESS}/lists/modify/${field}/${type}/${author}/${list}/${update} `,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        account,
        signature,
        public_key,
        hivesigner
      })
    }
  );
}

export async function followList(
  author: string,
  slug: string,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/lists/follow/${author}/${slug}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Connection: "keep-alive"
    },
    body: JSON.stringify({
      account,
      signature,
      public_key,
      hivesigner
    })
  });
}

export async function unfollowList(
  author: string,
  slug: string,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/lists/unfollow/${author}/${slug}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      account,
      signature,
      public_key,
      hivesigner
    })
  });
}

const bookmarkWarmup = (account: String) =>
  fetch(`/helpers/bookmark/${account}`).then(() => {});

export async function createBookmarkFolder(
  folder: string,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/bookmarks/folder/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Keep-Alive": "timeout=3600000, max=100000",
      Connection: "keep-alive"
    },
    body: JSON.stringify({
      folder,
      account,
      signature,
      public_key,
      hivesigner
    })
  }).then(() => bookmarkWarmup(account));
}

export async function removeBookmarkFolder(
  folder: string,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/bookmarks/folder/remove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      folder,
      account,
      signature,
      public_key,
      hivesigner
    })
  }).then(() => bookmarkWarmup(account));
}

export async function addBookmark(
  author: string,
  permlink: string,
  folder: string,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/bookmarks/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      author,
      permlink,
      folder,
      account,
      signature,
      public_key,
      hivesigner
    })
  }).then(() => bookmarkWarmup(account));
}

export async function removeBookmark(
  author: string,
  permlink: string,
  folder: string,
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/bookmarks/remove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      author,
      permlink,
      folder,
      account,
      signature,
      public_key,
      hivesigner
    })
  }).then(() => bookmarkWarmup(account));
}

export async function clearBookmarks(
  account: string,
  signature: string,
  public_key: string,
  hivesigner?: boolean
) {
  return await fetch(`${SERVER_ADRESS}/bookmarks/clear`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      account,
      signature,
      public_key,
      hivesigner
    })
  }).then(() => bookmarkWarmup(account));
}

export async function getAccountReferrals(account: string) {
  try {
    const result = await fetch(`${SERVER_ADRESS}/referrals/${account}`, {
      headers: {
        "Keep-Alive": "timeout=3600000, max=100000",
        Connection: "keep-alive"
      }
    }).then(async x => await x.json());

    return result;
  } catch (e) {
    return {};
  }
}

export const leocache = persistentDevCache("leocache", () => new LeoCache());
