import produce from "immer";
import { setter, type ImmerStateCreator } from "./";

export type ThemeMode = "Light" | "Dimmed" | "Dark";
export type ThemeColor =
  | "Orange"
  | "Yellow"
  | "Blue"
  | "Pink"
  | "Purple"
  | "Green";
export type PostReward = "Half" | "Full" | "Burn" | "Declined";

export type AccountSettings = {
  theme: ThemeMode;
  color: ThemeColor;
  typography: number;
  thread_weight: number;
  shorts_weight: number;
  post_weight: number;
  post_reward: PostReward;
  post_auto_reblog: boolean;
  post_auto_twitter: boolean;
  hide_links: boolean;
  default_currency: string;
  default_feed: string;
  default_beneficiaries: string[];
};

interface State extends AccountSettings {
  dark: boolean;
}

type Actions = {
  setSettings: (settings: State | Partial<State>) => void;
  setTheme: (theme: ThemeMode) => void;
  setColor: (theme: ThemeColor) => void;
  setTypography: (typo: number) => void;
  setThreadWeight: (weight: number) => void;
  setPostWeight: (weight: number) => void;
  setShortsWeight: (weight: number) => void;
  setPostReward: (reward: PostReward) => void;
  setPostAutoReblog: (option: boolean) => void;
  setPostAutoTwitter: (option: boolean) => void;
  setHideLinks: (option: boolean) => void;
  setDefaultCurrency: (currency: string) => void;
  setDefaultFeed: (feed: string) => void;
  setDefaultBeneficiaries: (beneficiaries: string[]) => void;
};

export type SettingsStore = State & Actions;

export const createSettingsStore: ImmerStateCreator<SettingsStore> = set => ({
  // state
  dark: false,
  theme: "Light",
  color: "Orange",
  typography: 16,
  thread_weight: 0,
  shorts_weight: 0,
  post_weight: 0,
  post_reward: "Half",
  post_auto_reblog: false,
  post_auto_twitter: false,
  hide_links: false,
  default_currency: "USD",
  default_feed: "foryou",
  default_beneficiaries: [],

  // actions
  setSettings: (settings: State | Partial<State>) => {
    set(
      produce(state => {
        if (!settings) return state;

        const newSettings = {
          ...state.settings,
          ...settings,
          dark: settings.theme !== "Light"
        };

        state.settings = newSettings;
      })
    );
  },
  setTheme: (theme: ThemeMode) =>
    setter<ThemeMode>("settings", set, "theme", theme),
  setColor: (color: ThemeColor) =>
    setter<ThemeColor>("settings", set, "color", color),
  setTypography: (typo: number) =>
    setter<number>("settings", set, "typography", typo),
  setThreadWeight: (weight: number) =>
    setter<number>("settings", set, "thread_weight", weight),
  setShortsWeight: (weight: number) =>
    setter<number>("settings", set, "shorts_weight", weight),
  setPostWeight: (weight: number) =>
    setter<number>("settings", set, "post_weight", weight),
  setPostReward: (reward: PostReward) =>
    setter<PostReward>("settings", set, "post_reward", reward),
  setPostAutoReblog: (option: boolean) =>
    setter<boolean>("settings", set, "post_auto_reblog", option),
  setPostAutoTwitter: (option: boolean) =>
    setter<boolean>("settings", set, "post_auto_twitter", option),
  setHideLinks: (option: boolean) =>
    setter<boolean>("settings", set, "hide_links", option),
  setDefaultCurrency: (currency: string) =>
    setter<string>("settings", set, "default_currency", currency),
  setDefaultFeed: (feed: string) =>
    setter<string>("settings", set, "default_feed", feed),
  setDefaultBeneficiaries: (beneficiaries: string[]) =>
    setter<string[]>("settings", set, "default_beneficiaries", beneficiaries)
});
